import React from "react"
import BannerShort from "../BannerShort/BannerShort"
import { LeftArticle } from "../LeftArticle/LeftArticle"


const bannerTitle = "Terms of use"
const bannerImg = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/TermsAndPolicyPages/background-terms-of-use.jpg`
const termOfUse = [
    {
        description: `<p class="mb-4">Neurond Technology JSC. and/or its affiliated entities ("Neurond," "Neurond AI," "we," or "us") provide access to the website found at www.neurond.com (referred to as the "Site") under the conditions stated in the Terms of Use presented herewith. Please review the following Terms of Use that govern your usage of this Site.</p>
        <p class="mb-4">We reserve the right to modify the Terms of Use. It is essential to routinely monitor the Site for any updates concerning these Terms of Use. If you continue to use the Site or provide us with information through its use after we implement any changes to the Terms of Use, you are indicating your acceptance of the revised provisions in the updated Terms of Use.</p>
        <p>The Terms of Use were last modified on July 31, 2023.</p>`
    },
    {
        title: "1. Authorization of Use",
        description: `<p class="mb-4">You are permitted to download, view, copy, and print documents, images, videos, and other content available on the Site (referred to as the "Content") under the following conditions:</p>
            <ul>
            <li>The Content may only be used for your personal, informational, non-commercial purposes or for your organization's use in connection with products or services received from Neurond.</li>
            <li>The Content must not be modified or altered in any way.</li>
            </ul>
            <p>Unless explicitly stated here, you are not allowed to use, download, upload, copy, print, display, perform, reproduce, publish, license, post, transmit, or distribute any information from this Site, in whole or in part, without obtaining prior written permission from Neurond. Further limitations regarding your use of specific Content may be outlined within the Content itself or the page from which it is accessible.
        All rights, title, and interest not expressly granted are reserved by Neurond.</p>`
    },
    {
        title: "2. Disclaimers",
        description: `<p class="mb-4">There are no warranties on the Site and our Content. Neurond disclaims all implied and express warranties of merchantability, suitability for a particular purpose, title, and non-infringement with respect to the website and any Content. Even if Neurond has been informed of the possibility of such damages, we won't be liable for any damages for loss of use, data, or profits, whether resulting from a contract, negligence, or other tortious actions, connected with, or arising out of, the use or performance of the Site or any Content.</p>
        <p>The information on the website should only be regarded as advisory and not as a replacement for any professional services that you could receive from Neurond. Neurond has the right to remove, substitute, or modify the information without notice and is not bound to keep the content updated. The content or some parts of the site may contain typographical errors or omissions.</p>`
    },
    {
        title: "3. User Submissions",
        description: `User Submissions are any feedback, comments, recommendations that users provide to Neurond through the Site. Neurond is free to utilize User Submissions in any way it sees fit. There are no endorsements of any User Submission, and we don't guarantee that they are correct.`
    },
    {
        title: "4. Site Restrictions",
        description: `<p class="mb-4">You must guarantee that you will not use the Site for any purposes that are illegal or against the Terms of Use to use the Site. You won't provide the Site with any information that is untrue, deceptive, or inaccurate. You are solely responsible and liable for all your acts or omissions that take place while using the Site, and you agree to abide by all applicable local, state, national, and international laws and regulations. You will not use the Site to:</p>
        <ul>
        <li>Violate or infringe on another person's legal rights (including, but not limited to, their rights to privacy, publicity, and intellectual property).</li>
        <li>Post, share, or otherwise transmit any information that is harmful, inappropriate, profane, vulgar, infringing, obscene, tortious, indecent, immoral, or otherwise objectionable.</li>
        <li>Send or upload any content to the website that is contaminated with viruses, worms, time bombs, cancelbots, or other potentially destructive software.</li>
        <li>VM* Disrupt or interfere with the servers or networks of the Site.</li>
        <li>Use another party's account, login information, or password to access the Site.</li>
        <li>Try to access the Site, other accounts, computers systems, or networks connected to the Service through password mining or another method.</li>
        <li>Collect others’ information from the Site.</li>
        </ul>
        <p>Neurond will not keep track of how you use the website or any of your sessions' material. However, we reserve the right to always evaluate, keep, disclose, and monitor any information as required to fulfill any relevant.</p>`
    },
    {
        title: "5. Infringement Claims Policy",
        description: `<p>If you see that some of the Site's content has been duplicated in a way that constitutes a copyright violation, please get in touch with us. We might look into it and respond accordingly.</p>`
    },
    {
        title: "6. Privacy",
        description: `<p class="mb-4">Your personal information provided to our Site is handled in accordance with the Privacy Policy posted on this Site. Other Web sites are not covered by our privacy policy, and we disclaim all responsibility for the data collecting and dissemination practices employed by third parties.</p>
        <p>You must read and understand the Privacy Policy to use this site, and you confirm that you have done so by using it. Please do not access the Site unless you agree with the conditions of the Privacy Policy.</p>`
    },
    {
        title: "7. Contact Information",
        description: `<p class="mb-4">Please send any inquiries, comments, or other correspondence regarding these Terms of Use to contact@neurond.com.</p>`
    }
]

const TermOfUse = () => (
    <section>
        <BannerShort title={bannerTitle} backgroundImage={bannerImg} />
        <div className="wrapper align-items-start mb-5">
        {
            termOfUse.map((item, index) => {
                return (
                    <LeftArticle data={item} />
                )
            })
        }
        </div>
    </section>
)

export default TermOfUse
