import React from "react"
import "./LeftArticle.scss"

export const LeftArticle = ({ data: { title, description } }) => {
  return (
    <div className="left-article-wrapper mt-3">
      <article>
        <h2 className="h3-text mg-y-md">{title}</h2>
        <div className="text" dangerouslySetInnerHTML={{__html: description}}></div>
      </article>
    </div>
  )
}
