import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TermOfUse from "../components/TermsOfUse/TermsOfUse"

const TermOfUsePage = () => {
  const { t } = useTranslation()
  return (
    <Layout showHeaderBG={true}>
      <SEO title={t("Terms Of Use")} />
      <TermOfUse />
    </Layout>
  )
}

export default TermOfUsePage
