import React from "react"
import "./BannerShort.scss"

const BannerShort = ({ title, backgroundImage }) => {
  return (
    <section
      className="banner-short"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="overlay d-flex">
        <div className="wrapper">
            <h1 className="text-white">{title}</h1>
        </div>
      </div>
    </section>
  )
}
export default BannerShort
